import * as React from "react";
import Success from "../components/success";
import Footer from "../components/footer";

const SuccessPage = () => {
  return (
    <main>
      <div id="wrapper">
        <div id="main">
          <Success />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default SuccessPage;
