import React from "react";
import { Link } from "gatsby";

const Success = () => {
  return (
    <section id="three">
      <div className="container">
        <h3>Ihre Nachricht wurde gesendet</h3>
        <p>
          <Link to="/">Zurück zur Startseite</Link>
        </p>
      </div>
    </section>
  );
};
export default Success;
